import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import {
  Section,
  SectionHeading
} from "../../styledComponents/section"
import { ContactSection } from "../../styledComponents/contact"
import Layout from "../../components/layout"
import FeedbackForm from "../../components/formFeedback"
import SEO from "../../components/seo"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const Feedback = ({ data, location }) => {
  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />x
    </Helmet>
    <Layout displayNavBorder location={location}>
    <SEO title="Treatment and Workshop Feedback from" />
      <Section>
        <Heading level={1}>
          Feedback
        </Heading>
        <ContactSection>
          <FeedbackForm />
        </ContactSection>
      </Section>
    </Layout>
    </>
  )
}

export default Feedback

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "contact" }) {
      title
      content
    }
  }
`
