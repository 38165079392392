import React from "react"
import {
  StyledOption,
} from "../styledComponents/contact"

const AudioProgramSelectOptions = (data) => {
      return (
        <>
          <StyledOption value="default" disabled hidden>Please select?</StyledOption>
          <StyledOption value="Permission to Sleep">Permission to Sleep</StyledOption>
          <StyledOption value="Permission to Reset Anxiety">Permission to Reset Anxiety</StyledOption>
          <StyledOption value="The Essential Mindfulness Collection">The Essential Mindfulness Collection</StyledOption>
          <StyledOption value="Chakra Balancing Collection">Chakra Balancing Collection</StyledOption>
          <StyledOption value="Quantum-Touch Braing Healing">Quantum-Touch Braing Healing</StyledOption>
          <StyledOption value="Quantum-Touch Balancing Structure">Quantum-Touch Balancing Structure</StyledOption>
          <StyledOption value="Quantum-Touch Chakra Balancing">Quantum-Touch Chakra Balancing</StyledOption>
          <StyledOption value="Quantum-Touch Morning Meditation">Quantum-Touch Morning Meditation</StyledOption>
          {/* <StyledOption value="Self Healing Mastery">Self Healing Mastery</StyledOption> */}
        </>
      )
}

export default AudioProgramSelectOptions
