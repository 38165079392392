import React from "react"
import {
  StyledOption,
} from "../styledComponents/contact"

const EventDates = (data) => {
  if (data.type === 'upcoming') {
    return (
      <>
        <StyledOption value="default" disabled hidden>Select workshop?</StyledOption>
        <StyledOption value="QT Level 1 - September 16th - London, N22">QT Level 1 - September 16th - London, N22</StyledOption>
        <StyledOption value="QT Energy Boost Mini - September, 30th - Online">QT Energy Boost Mini - September, 30th - Online</StyledOption>
        <StyledOption value="QT Level 1 - Oct 8th - Online">QT Level 1 - Oct 8th - Online</StyledOption>
        <StyledOption value="QT Self Created Health - Oct 28th - Online">QT Self Created Health - Oct 28th - Online</StyledOption>
        <StyledOption value="QT Energy Boost Mini - November, 6th - Online">QT Energy Boost Mini - November, 6th - Online</StyledOption>
        <StyledOption value="QT Level 2 - Dec 3rd - Online">QT Level 2 - Dec 3rd - Online</StyledOption>
        <StyledOption value="QT Manifesting Miracles - Jan 6th - Online">QT Manifesting Miracles - Jan 6th - Online</StyledOption>
      </>
    )
  }
  // else if (data.type === 'energy-boost-israel') {
    //   return (
      //     <>
      //     <StyledOption value="dec-6-energy-boost">QT Energy Boost - December 6th</StyledOption>
      //     </>
      //   )
      // } 
      else {
        return (
          <>
        <StyledOption value="default" disabled hidden>Please select?</StyledOption>
        <StyledOption value="QT Energy Boost Mini - July, 17th - Online">QT Energy Boost Mini - July, 17th - Online</StyledOption>
        <StyledOption value="QT Supercharging - July 8th - London, N22">QT Supercharging - July 8th - London, N22</StyledOption>
        <StyledOption value="QT Level 1 - June 19th - Online">QT Level 1 - June 19th - Online</StyledOption>
        <StyledOption value="QT Energy Boost Mini - May 20th - Online">QT Energy Boost Mini - May 20th - Online</StyledOption>
        <StyledOption value="QT Level 2 - May 7th - Online">QT Level 2 - May 7th - Online</StyledOption>
        <StyledOption value="jan-22-level-1">QT Level 1 - January 22nd</StyledOption>
        <StyledOption value="jan-10-energy-boost">QT Energy Boost - January 10th</StyledOption>
        <StyledOption value="dec-6-energy-boost">QT Energy Boost - December 6th</StyledOption>
        <StyledOption value="dec-4-level-2">QT Level 2 - December 4th</StyledOption>
        <StyledOption value="nov-29-energy-boost">QT Energy Boost Mini Class - November 29th</StyledOption>
        <StyledOption value="nov-20-supercharging">QT Supercharging - November 20th</StyledOption>
        <StyledOption value="oct-16-level-1">QT Level 1 - October 16th</StyledOption>
        <StyledOption value="sep-11-level-1">QT Level 1 - September 11th</StyledOption>
        <StyledOption value="jul-14-permission-nervous-system">Permission to Reset™ Your Nervous System - July</StyledOption>
        <StyledOption value="jul-17-level-2">QT Level 2 - July 17th</StyledOption>
        <StyledOption value="jun-26-supercharging">QT Supercharging - June 26th</StyledOption>
        <StyledOption value="may-15-level-1">QT Level 1 - May 15th</StyledOption>
        <StyledOption value="may-2-sch">QT Self Created Health - May 2nd</StyledOption>
        <StyledOption value="mar-27-level-2">QT Level 2 - March 27th</StyledOption>
        <StyledOption value="mar-20-supercharging">QT Supercharging - March 20th</StyledOption>
        <StyledOption value="feb-6-level-1">QT Level 1 - February 6th 2023</StyledOption>
        <StyledOption value="jan-30-level-2">QT Level 2 - January 30th 2023</StyledOption>
        <StyledOption value="jan-23-permission-nervous-system">Permission to Reset Your Nervous System - January</StyledOption>
        <StyledOption value="dec-11-speen-therapy">Spoon Therapy - December 10th & 11th 2022</StyledOption>
        <StyledOption value="dec-5-supercharging">QT Supercharging - December 5th 2022</StyledOption>
        <StyledOption value="nov-21-level-1">QT Level 1 - November 21st 2022</StyledOption>
        <StyledOption value="oct-12-sch">QT Self Created Health - October 12th 2022</StyledOption>
      </>
    )
  }
}

export default EventDates
